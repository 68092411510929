<template>
    <div class="container mx-auto mx-4 my-3">
        <div class="flex items-center mb-3 ">
            <h1>Din konto</h1>
        </div>

        <div class="rounded-sm bg-white shadow mb-5">
            <validation-observer
                v-slot="{ handleSubmit }"
                ref="observer">
                <form
                    class="p-4"
                    @submit.prevent="handleSubmit(updateAccount)">
                    <h3>Basis oplysninger</h3>
                    <div class="form-group">
                        <v-input
                            v-model="model.emailAddress"
                            label="Emailadresse"
                            rule="required"
                            name="Emailaddresse"/>
                    </div>

                    <div class="form-group">
                        <v-input
                            v-model="model.name"
                            label="Navn"
                            rule="required"
                            name="Navn"/>
                    </div>

                    <div class="form-group text-right">
                        <button
                            type="submit"
                            class="btn --primary">
                            Opdater
                        </button>
                    </div>
                </form>
            </validation-observer>
        </div>

        <div class="rounded-sm bg-white shadow mb-5">
            <validation-observer
                v-slot="{ handleSubmit }"
                ref="passwordObserver">
                <form
                    class="p-4"
                    @submit.prevent="handleSubmit(changePassword)">
                    <h3>Skift adgangskode</h3>
                    <div class="form-group">
                        <v-input
                            v-model="passwordModel.newPassword"
                            type="password"
                            label="Ny adgangskode"
                            rule="required"
                            name="Ny adgangskode"/>
                    </div>

                    <div class="form-group">
                        <v-input
                            v-model="passwordModel.confirmNewPassword"
                            type="password"
                            label="Bekræft ny adgangskode"
                            rule="required"
                            name="Ny adgangskode"/>
                    </div>

                    <div class="form-group text-right">
                        <button
                            type="submit"
                            class="btn --primary">
                            Skift adgangskode
                        </button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticationService from '../core/services/account/authentication.service';
import { UpdateAccountModel, UpdatePasswordModel } from '../_typedef/missile';
import bus from '@/core/Bus';

@Component({})
export default class Account extends Vue {
    model: UpdateAccountModel = { emailAddress: '', name: '' };
    passwordModel: UpdatePasswordModel = { newPassword: '', confirmNewPassword: '' };

    async created() {
        const context = authenticationService.context;

        this.model = {
            name: context.displayName,
            emailAddress: context.userName
        };
    }

    async updateAccount() {
        await authenticationService.updateAccount({
            account: this.model
        });

        bus.info('Dine oplysninger er opdateret');
    }

    async changePassword() {
        await authenticationService.updateAccount({
            password: this.passwordModel
        });

        this.passwordModel = { newPassword: '', confirmNewPassword: '' };
        (this.$refs.passwordObserver as any).reset();

        bus.info('Din adgangskode er ændret');
    }
}
</script>